<template>
  <!-- <input v-maska="maska" v-model="inputValue" :placeholder="placeholder" /> -->
  <n-input :id="id" v-model:value="inputValue" :status="v ? 'error' : 'success'" v-maska="maska" :type="type"
    :placeholder="placeholder" :disabled="disabled" :loading="loading" :size="size">
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <template #suffix>
      <slot name="suffix"></slot>
    </template>
  </n-input>
</template>

<script>
export default {
  props: {
    maska: {
      default: "",
    },
    id: {
      default: "",
    },
    value: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    v: {
      default: false,
    },
    disabled: {
      default: false,
    },
    loading: {
      default: false,
    },
    size: {
      default: "",
    },
    type: {
      default: "text",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.maska && this.maska.length < val.length) {
          let res = val.slice(0, this.maska.length);
          this.$emit("update:value", res);
        } else this.$emit("update:value", val);
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
input {
  @apply flex w-full h-full py-1 px-2;
  letter-spacing: 2px;
}
</style>