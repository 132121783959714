import ApiService from "./api.service";
let main = "candidateDocument";
export default {
  sendDocs({ data }) {
    let formdata = new FormData();
    for (const id in data) {
      if (id == "regionId") {
        formdata.append(id, data[id]);
      } else if (id == "licenceTypeIds") {
        formdata.append(id, JSON.stringify(data[id]));
      } else {
        formdata.append(`multipartFile`, data[id]);
        formdata.append(`docTypeIds`, id);
      }
    }
    return ApiService.formData(`${main}/createCandidateDoc`, formdata);
  },
  ownEmployee() {
    return ApiService.get(`${main}/ownEmployee`);
  },
  candidateDocument(id) {
    return ApiService.get(`${main}/get-by-candidateId/${id}`);
  },
  candidateDocumentHistory(candidateDocId) {
    return ApiService.get(
      `${main}History/history-by-candidateDocId/${candidateDocId}`
    );
  },
  updatePhoto(date) {
    return ApiService.get(`${main}/updatePhoto?givenDate=${date}`);
  },
  // GET http://localhost:8282/api/v.1/candidateDocument/passportDataByPinFL
  passportDataByPinFL(pinFL) {
    return ApiService.get(`${main}/passportDataByPinFL?pinFL=${pinFL}`);
  },
  // http://localhost:8282/api/v.1/candidateDocument/call-api
  callApi(inn) {
    return ApiService.post(`${main}/call-api`, { inn });
  },
};
