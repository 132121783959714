/* eslint-disable */
import i18n from "@/core/i18n/i18n";
import { useToast } from "vue-toastification";
import { toast as toast2 } from "vue3-toastify";

// let { locale } = i18n.global;
const toast = useToast();
let base = process.env.VUE_APP_ROOT_URL;
let base2 = process.env.VUE_APP_ROOT_URL2;

let options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};
let options2 = {
  position: "top-right",
  autoClose: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  icon: true,
  rtl: false,
};

const Util = {
  localeKey(key='name') {
    let { locale } = i18n.global;
    return locale == "lt"
      ? key + "Lt"
      : locale == "ru"
      ? key + "Ru"
      : locale == "eng"
      ? key + "En"
      : locale == "uz"
      ? key + "Uz"
      : "";
  },
  getImgPath(upload) {
    if (upload?.includes("files/")) return `${base}/${upload}`;
    else if (upload) return `${base2}/${upload}`;
    else return require("@/assets/img/default_male.jpg");
  },
  showError(title) {
    toast.error(title, options);
  },
  errorMsg(title) {
    // <div>
    //         {this.$t("orders.limitedByContragent")} <br />{" "}
    //         <strong>{this.$t("reason")}</strong> : {this.orderReason}
    //       </div>
    toast2.error(title, options2);
  },
  showSuccess(title) {
    toast.success(title, options);
  },
  showInfo(title) {
    toast.info(title, options);
  },
  successMsg(message) {
    toast2.success(message, options2);
  },
  showWarning(title) {
    toast2.warning(title, options2);
  },
  timetype(time) {
    let value = new Date(time);
    let day = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
    let month =
      value.getMonth() + 1 < 10
        ? `0${value.getMonth() + 1}`
        : value.getMonth() + 1;
    let year = value.getFullYear();
    let valuenew = `${day}-${month}-${year}`;

    return valuenew;
  },
  ddmmyyyyRestor(time) {
    let date = time.split("-");
    date = `${date[2] || ""}-${date[1] || ""}-${date[0] || ""}`;
    return date;
  },
};

export default Util;
