<template>
  <div class="flex flex-col">
    <div class="flex flex-row mb-4 gap-2" v-if="$props.templateKey">
      <button
        @click="activeTab = 'esp'"
        style="
          background-image: linear-gradient(
            to bottom,
            rgb(111, 146, 192),
            rgb(66, 87, 159)
          );
          color: white;
          border-radius: 10px;
          width: 100px;
        "
        class="cursor-pointer shadow flex justify-center items-center"
      >
        <img width="20" src="@/assets/e-imzo.png" alt="eimzo" /><span
          class="ml-2 mb-1"
          style="font-size: 20px"
        >
          - imzo</span
        >
      </button>
      <button
        @click="activeTab = 'mobileId'"
        style="
          background-image: linear-gradient(
            to bottom,
            rgb(111, 146, 192),
            rgb(66, 87, 159)
          );
          color: white;
          border-radius: 10px;
          width: 100px;
        "
        class="cursor-pointer shadow flex justify-center items-center whitespace-nowrap"
      >
        <span class="px-2" style="font-size: 20px">Mobile ID</span>
      </button>
    </div>
    <div v-if="activeTab === 'esp'">
      <div
        v-for="(item, index) in keysList"
        :key="index"
        class="bg-white border border-blue mb-5 p-4"
      >
        <COverlay
          :loading="loadingButton && item.UID == selectedKey.UID"
          class="pb-4"
        >
          <div class="text-2xl text-blue_main text-opacity-75 mb-4">
            {{ item.CN }}
          </div>
          <div class="grid grid-cols-3 gap-y-5">
            <div>
              <div class="text-black text-opacity-70">STIR</div>
              <div class="text-lg">{{ item.TIN }}</div>
            </div>
            <div>
              <div class="text-black text-opacity-70">JSHSHIR</div>
              <div class="text-lg">{{ item.PINFL }}</div>
            </div>
            <div>
              <div class="text-black text-opacity-70">Sertifikat raqami</div>
              <div class="text-lg">{{ item.UID }}</div>
            </div>
            <div>
              <div class="text-black text-opacity-70">Tashkilot</div>
              <div class="text-lg">{{ item.O || "-" }}</div>
            </div>
            <div>
              <div class="text-black text-opacity-70">
                Sertifikatning amal qilish muddati
              </div>
              <div class="text-lg">
                {{ new Date(item.validFrom).ddmmyyyy?.() }} -
                {{ new Date(item.validTo).ddmmyyyy?.() }}
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-4">
            <button
              :disabled="loadingButton"
              @click.prevent="(withoutChallange ? getData : keySelected)(item)"
              class="c-upload"
            >
              Ushbu kalitni tanlang
            </button>
          </div>
        </COverlay>
      </div>
    </div>
    <div class="flex flex-col" v-if="activeTab === 'mobileId'">
      <div class="flex flex-row gap-4">
        <c-form-input
          class="w-full"
          :size="'large'"
          v-model:value="code"
          :placeholder="$t('code')"
          maska="######"
        />
        <div v-if="second" style="font-size: 20px" class="whitespace-nowrap">
          {{ minute }} daqiqa {{ second }} soniya
        </div>
        <div>
          <button
            :disabled="second"
            @click.prevent="getSms()"
            class="c-upload whitespace-nowrap"
          >
            Sms yuborish
          </button>
        </div>
      </div>
      <button
        :disabled="code?.length !== 6"
        @click.prevent="mobileIdVerify()"
        class="c-upload mt-2"
      >
        Tasdiqlash
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
var EIMZO_MAJOR = 3;
var EIMZO_MINOR = 37;

const errorCAPIWS =
  "Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.";
const errorBrowserWS =
  "Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.";
const errorUpdateApp =
  'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>';
const errorWrongPassword = "Пароль неверный.";

import CFormInput from "@/components/formElements/CFormInput.vue";
import UserService from "@/services/login.service";
import { useToast } from "vue-toast-notification";
import COverlay from "@/components/COverlay.vue";
import AppSettings from "@/core/settings";
const $toast = useToast({
  position: "top-right",
});

export default {
  name: "Signature",
  components: {
    COverlay,
    CFormInput,
  },
  // props: ["selectedDoc", "givenDate", "withComment", "templateKey", 'withoutChallange'],
  props: {
    selectedDoc: {
      default: {},
    },
    withComment: {
      default: "",
    },
    givenDate: {
      default: "",
    },
    templateKey: {
      default: "",
    },
    withoutChallange: {
      default: false,
    },
    isLogin: {
      default: false,
    },
  },
  data() {
    return {
      second: 0,
      code: "",
      minute: 0,
      otp_id: "",
      comment: "",
      keysList: [],
      loading: false,
      activeTab: "esp",
      signModal: false,
      modalTitle: null,
      dataToSign: null,
      currentItem: null,
      selectedKey: null,
      challangeData: null,
      loadingButton: false,
      dataToSignIfNot: null,
      isLogin: window.location.pathname === "/",
    };
  },
  async created() {
    this.activeTab = "esp";
    await this.appLoad();
  },
  methods: {
    async getSms() {
      try {
        const response = await UserService.mobileIdByPin(
          this.$props.templateKey
        );

        this.otp_id = response.data.data?.otp_id;
        this.minute = `${response.data.data?.duration}`.slice(0, 3) / 60;

        if (this.minute) {
          setInterval(() => {
            if (this.second) this.second = this.second - 1;

            if (!this.second && this.minute) {
              this.minute = this.minute - 1;
              this.second = 60;
            }
          }, 1000);
        }
      } catch (error) {
        this.$showError(error.response?.data?.message);
        console.log(error);
      }
    },
    async mobileIdVerify() {
      try {
        const response = await UserService.mobileIdVerify(this.code);

        if (response.data.data) {
          await this.$emit("success", { otp_id: this.otp_id });
        }
      } catch (error) {
        this.$showError(
          error.response?.data?.message === "OTP_NOT_FOUND"
            ? "Kodni muddati o'tgan"
            : error.response?.data?.message
        );
        console.log(error);
      }
    },
    async keySelected(e) {
      try {
        this.selectedKey = e;
        this.loadingButton = true;

        let res = await UserService.getChallenge(this.$props.isLogin);

        this.challangeData = res.data;

        await this.getData(this.selectedKey);
      } catch {
      } finally {
        this.loadingButton = false;
      }
    },
    async sendToBackendSignedData(signedData) {
      try {
        const payload = {
          signedContent: signedData,
          // inn: this.selectedKey.TIN,
          pnfl: this.selectedKey.PINFL,
        };
        if (this.withComment) {
          await this.$emit("success", { ...payload, comment: this.comment });
        } else {
          await this.$emit("success", payload);
        }
      } catch (error) {}
    },
    async openSignModal(item) {
      try {
        this.loadingButton = true;

        this.currentItem = item;
        this.modalTitle = item.serialNumber + " - " + item.CN;
        if (this.dataToSign === null) {
          await UserService.getKeyForLoginSign(item.TIN, item.PINFL).then(
            (response) => {
              this.dataToSignIfNot = response.data;
              this.signByEimzo();
            }
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingButton = false;
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    clearCookie(name) {
      document.cookie =
        name + "=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    setCookie(cname, cvalue) {
      var now = new Date();
      var time = now.getTime();
      time += 3600 * 6000;
      now.setTime(time);
      let expires = "expires=" + now.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    async signByEimzo() {
      this.loadingButton = true;
      let vm = this;
      var data = this.$props.withoutChallange
        ? JSON.stringify(this.selectedDoc)
        : this.challangeData?.challenge;

      const promise = new Promise((resolve, reject) => {
        let keyId = this.getCookie("keyId-" + vm.currentItem.TIN);
        if (keyId == "") {
          EIMZOClient.loadKey(
            vm.currentItem,
            function (id) {
              EIMZOClient.createPkcs7(
                id,
                data,
                null,
                function (pkcs7) {
                  vm.setCookie("keyId-" + vm.currentItem.TIN, id);
                  resolve(pkcs7);
                },
                function (e, r) {
                  reject("error");
                  vm.loadingButton = false;
                  if (r) {
                    if (r.indexOf("BadPaddingException") != -1) {
                      $toast.error(errorWrongPassword);
                    } else {
                      $toast.error(r);
                    }
                  } else {
                    document.getElementById("keyId").innerHTML = "";
                    $toast.error(errorBrowserWS);
                  }
                  if (e) vm.wsError(e);
                }
              );
            },
            function (e, r) {
              reject("error");
              vm.loadingButton = false;
              if (r) {
                if (r.indexOf("BadPaddingException") != -1) {
                  $toast.error(errorWrongPassword);
                } else {
                  $toast.error(r);
                }
              } else {
                vm.$showMsgErro(errorBrowserWS);
              }
              if (e) vm.wsError(e);
            }
          );
        } else {
          EIMZOClient.createPkcs7(
            keyId,
            data,
            null,
            function (pkcs7) {
              resolve(pkcs7);
            },
            function (e, r) {
              reject("error");
              vm.loadingButton = false;
              if (r) {
                vm.clearCookie("keyId-" + vm.currentItem.TIN);
                if (r.indexOf("BadPaddingException") != -1) {
                  $toast.error(errorWrongPassword);
                } else {
                  $toast.error(r);
                }
              } else {
                document.getElementById("keyId").innerHTML = "";
                $toast.error(errorBrowserWS);
              }
              if (e) vm.wsError(e);
            }
          );
        }
      });
      promise
        .then(
          async (success) => {
            await vm.sendToBackendSignedData(success);
          },
          (error) => {
            $toast.error("Server error...");
          }
        )
        .finally(() => {
          vm.signModal = false;
          vm.loadingButton = false;
        });
    },
    async getData(item) {
      this.selectedKey = item;
      await this.openSignModal(this.selectedKey);
    },
    uiCreateItem(itmkey, vo) {
      let vm = this;
      var now = new Date();
      vo.expired = dates.compare(now, vo.validTo) > 0;
      var itm = document.createElement("option");
      itm.value = itmkey;
      itm.text = vo.CN;
      if (!vo.expired) {
      } else {
        itm.style.color = "gray";
        itm.text = itm.text + " (срок истек)";
      }
      vm.keysList.push(vo);
      itm.setAttribute("vo", JSON.stringify(vo));
      itm.setAttribute("id", itmkey);
      return itm;
    },
    async uiLoadKeys() {
      let vm = this;
      EIMZOClient.listAllUserKeys(
        function (o, i) {
          var itemId = "itm-" + o.serialNumber + "-" + i;
          return itemId;
        },
        function (itemId, v) {
          return vm.uiCreateItem(itemId, v);
        },
        function (items, firstId) {
          //vm.uiFillCombo(items);
          //vm.uiComboSelect(firstId);
        },
        function (e, r) {
          console.log(e);
          console.log(r);
          $toast.error(errorCAPIWS);
        }
      );
    },
    appLoad() {
      let vm = this;
      vm.loading = true;
      EIMZOClient.API_KEYS = AppSettings.api_keys;
      EIMZOClient.checkVersion(
        function (major, minor) {
          var newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR;
          var installedVersion = parseInt(major) * 100 + parseInt(minor);
          if (installedVersion < newVersion) {
            vm.loading = false;
            $toast.error(errorUpdateApp);
          } else {
            EIMZOClient.installApiKeys(
              function () {
                vm.loading = false;
                vm.uiLoadKeys();
              },
              function (e, r) {
                vm.loading = false;
                if (r) {
                  $toast.error(r);
                } else {
                  vm.wsError(e);
                }
              }
            );
          }
        },
        function (e, r) {
          vm.loading = false;
          if (r) {
            $toast.error(r);
          } else {
            $toast.error(e);
          }
        }
      );
    },
  },
};
</script>
<style>
.style-choos-contract .vs__search::placeholder,
.style-choos-contract .vs__dropdown-toggle,
.style-choos-contract .vs__dropdown-menu {
  border: 1px solid #e5eaee;
  padding: 8px;
  color: #394066;
  background: white;
}
</style>
