import RegionService from "@/services/region.service";

const state = () => {
  return {
    uzbRegions: [],
  };
};

const getters = {};

const actions = {
  async getUzbList({ commit }) {
    try {
      let res = await RegionService.uzbRegions();
      if (res?.data) {
        commit("setList", res?.data);
      }
    } catch {
      commit("setList", []);
    }
  },
};

const mutations = {
  setList(state, payload) {
    state.uzbRegions = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
