<template>
  <n-config-provider>
    <n-notification-provider>
      <router-view />
    </n-notification-provider>
  </n-config-provider>
</template>

<script>
/* eslint-disable  */
import { mapState, mapMutations } from "vuex";
import { TokenService } from "@/services/storage.service";
import { locale } from "./core/i18n/locales/uzLotin";
export default {
  name: "App",
  watch: {
    $route(to, from) {
      let fullName = TokenService.getFullName();
      let email = TokenService.getEmail() || "";
      let uploadPath = TokenService.getUploadPath();
      this.USER({ fullName, email, uploadPath });
    },
  },
  computed: {
    ...mapState("lawyer", ["fulName", "email", "uploadPath"]),
  },
  methods: {
    ...mapMutations("lawyer", ["USER"]),
    clearCookies() {
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
    },
    clearStorage() {
      sessionStorage.clear();
    },
    clearCache() {
      if (caches) {
        caches.keys().then(function (cacheNames) {
          cacheNames.forEach(function (cacheName) {
            caches.delete(cacheName);
          });
        });
      }
    },

    Tozalash() {
      document.cookie =
        "cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      if (caches) {
        caches.keys().then(function (cacheNames) {
          cacheNames.forEach(function (cacheName) {
            caches.delete(cacheName);
          });
        });
      }
      localStorage.clear();
      sessionStorage.clear();
    },
  },
  created() {
    let clearStatus = localStorage.getItem("clearStatus");
    if (!clearStatus) {
      this.clearCookies();
      this.clearStorage();
      this.clearCache();
      this.Tozalash();
      localStorage.setItem("clearStatus", true);
    }
  },
  mounted() {
    document.querySelectorAll("img").forEach((img) => {
      // mause right click disable
      img.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
      // dont open image in new tab
      img.addEventListener("mousedown", (e) => {
        e.preventDefault();
      });
      // dont open right click menu
      img.addEventListener("mouseup", (e) => {
        e.preventDefault();
      });
    });
    document.cookie =
      "cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    if (window.caches) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  // display: none;
}

.overflow-y-auto::-webkit-scrollbar {
  // -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  height: 10px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background: #c6d0d3;
}
.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #9da6a8; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
}
img {
  pointer-events: none !important;
}
.leaflet-bottom.leaflet-right {
  display: none !important;
}
body {
  @apply relative;
  &.USUAL {
    filter: none;
  }
  &.BLIND {
    filter: grayscale(100%) contrast(0.5) brightness(1.5);
  }
  &.BLIND2 {
    filter: grayscale(100%) contrast(1) brightness(1.5);
  }
}
</style>
