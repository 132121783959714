import ApiService, { createQuery } from "./api.service";
let main = "getInfo";
export default {
  getList() {
    return ApiService.get(`${main}/requestTemplateList`);
  },
  getById(id) {
    return ApiService.post(
      `${main}/getByRequestTemplateId?requestTemplateId=${id}`
    );
  },
  getRequestTypeList() {
    return ApiService.post(`${main}/requestTypeList?search=`, {
      page: 0,
      limit: 100,
    });
  },
  getDepartmentList() {
    return ApiService.get(`${main}/departmentList`);
  },
  departmentAdliyaList() {
    return ApiService.get(`${main}/departmentAdliyaList`);
  },
  legalAidList() {
    return ApiService.post(`${main}/legalAidList`);
  },
  monthOrYEarList() {
    return ApiService.post(`${main}/monthOrYEarList?search=`, {
      page: 0,
      limit: 100,
    });
  },
  directoryCivilAffairsList() {
    return ApiService.post(`${main}/directoryCivilAffairsList`, {
      page: 0,
      limit: 100,
    });
  },
  directory_criminal_caseList() {
    return ApiService.post(`${main}/directory_criminal_caseList`, {
      page: 0,
      limit: 100,
    });
  },
  directoryCourtList() {
    return ApiService.post(`${main}/directoryCourtList`, {
      page: 0,
      limit: 100,
    });
  },
  directoryLanguageList() {
    return ApiService.post(`${main}/directoryLanguageList?search=`, {
      page: 0,
      limit: 100,
    });
  },
  requestTemplateCreate({
    params,
    departmentId,
    licenseTypeIds,
    monthOrYearId,
    // period,
    regionIds,
    languageIds,
    phone,
    requestTypeId,
  }) {
    return ApiService.post(
      `${main}/requestTemplateCreate?departmentId=${
        departmentId || ""
      }&licenseTypeIds=${licenseTypeIds || ""}&monthOrYearId=${
        monthOrYearId || ""
      }&regionIds=${regionIds || ""}&languageIds=${languageIds || ""}&phone=${
        phone || ""
      }&requestTypeId=${requestTypeId || ""}`,
      params
    );
  },
  requestTemplateCreateQuery(data, params) {
    return ApiService.post(
      `${main}/requestTemplateCreate?${createQuery(data)}`,
      params
    );
  },
};
