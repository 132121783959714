const lawyerData = "LAWYER_DATA";
const changeState = "CHANGE_STATE";
const user = "USER";
const loading = "LOADING";
import LawyerService from "@/services/lawyer/lawyer.service";

const state = () => {
  return {
    list: [],
    total: 0,
    search: "",
    fullName: "",
    email: "",
    status: "",
    contragentId: "",
    regionId: "",
    limit: 24,
    page: 0,
    loading: false,
    femaleCount: 0,
    manCount: 0,
    uploadPath: "",
  };
};

// actions
const actions = {
  async getList({ commit, state }) {
    commit(loading, true);
    let data = {
      search: state.search,
      status: state.status,
      contragentId: state.contragentId,
      regionId: state.regionId,
      params: {
        limit: state.limit,
        page: state.page,
      },
    };
    try {
      let res = await LawyerService.getList(data);
      if (res.data) {
        commit(lawyerData, res.data);
      }
    } finally {
      commit(loading, false);
    }
  },
};

// mutations
const mutations = {
  [lawyerData](state, data) {
    state.list = data.list || [];
    state.total = data.total || 0;
    state.manCount = data.manCount || 0;
    state.femaleCount = data.femaleCount || 0;
  },
  [changeState](state, { regionId, contragentId, search, page, limit }) {
    state.regionId = regionId == undefined ? state.regionId : regionId;
    state.contragentId =
      contragentId == undefined ? state.contragentId : contragentId;
    state.search = search == undefined ? state.search : search;
    state.page = page == undefined ? state.page : page;
    state.limit = limit == undefined ? state.limit : limit;
  },
  [user](state, { email, fullName, uploadPath }) {
    state.email = email || "";
    state.fullName = fullName || "";
    state.uploadPath = uploadPath || "";
  },
  [loading](state, payload) {
    state.loading = payload;
  },
  setArchiveLawyer(state, archiveLawyer) {
    state.archiveLawyer = archiveLawyer;
  },
};

// getters
const getters = {
  darkMode: (state) => state.darkMode,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
