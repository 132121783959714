const allData = "CONTRAGENT_DATA";
const changeState = "CHANGE_STATE";
const loading = "LOADING";
import LawyerService from "@/services/lawyer/lawyer.service";

const state = () => {
  return {
    list: [],
    total: 0,
    isArchive: false,
    search: "",
    typeId: "",
    regionId: "",
    limit: 20,
    page: 0,
    loading: false,
  };
};

// actions
const actions = {
  async getList({ commit, state }) {
    commit(loading, true);
    let data = {
      search: state.search,
      typeId: state.typeId,
      isArchive: state.isArchive,
      regionId: state.regionId,
      params: {
        limit: state.limit,
        page: state.page,
      },
    };
    try {
      let res = await LawyerService.contragentList(data);
      if (res.data) {
        commit(allData, res.data);
      }
    } finally {
      commit(loading, false);
    }
  },
};

// mutations
const mutations = {
  [allData](state, data) {
    state.list = data.list || [];
    state.total = data.total || 0;
  },
  [changeState](state, { regionId, typeId, search, page, limit }) {
    // isArchive: false,
    // search: "",
    // typeId: "",
    // regionId: "",
    state.regionId = regionId == undefined ? state.regionId : regionId;
    state.typeId = typeId == undefined ? state.typeId : typeId;
    state.search = search == undefined ? state.search : search;
    state.page = page == undefined ? state.page : page;
    state.limit = limit == undefined ? state.limit : limit;
  },
  [loading](state, payload) {
    state.loading = payload;
  },
  setArchiveLawyer(state, archiveLawyer) {
    state.archiveLawyer = archiveLawyer;
  },
};

// getters
const getters = {
  darkMode: (state) => state.darkMode,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
