const state = () => {
  return {
    sidebarMenu: [
      {
        titleKey: "user.user",
        to: "/lawyer-profile/user-info",
        icon: require("@/assets/img/sidebar/user.svg"),
        views: ["USER", "NEW_DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "applicant",
        to: "/lawyer-profile/applicant",
        icon: require("@/assets/img/sidebar/users.svg"),
        views: ["USER", "LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
      },

      {
        titleKey: "forPersonsWithDisabilities",
        to: "/lawyer-profile/my-appeals",
        icon: require("@/assets/img/sidebar/requests.svg"),
        views: ["USER", "ADMIN"],
      },
      {
        titleKey: "forPersonsWithDisabilities",
        to: "/lawyer-profile/my-appeals/appeals",
        icon: require("@/assets/img/sidebar/requests.svg"),
        views: ["LAWYER", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "citizensInTheSocialSecurityRegister",
        to: "/lawyer-profile/my-applications",
        icon: require("@/assets/img/sidebar/requests.svg"),
        views: ["USER", "LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "sidebar.controlPanel",
        to: "/lawyer-profile/control-panel",
        icon: require("@/assets/img/sidebar/control-panel.svg"),
        views: ["LAWYER", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "sidebar.control",
        to: "/lawyer-profile/control",
        icon: require("@/assets/img/sidebar/contracts.svg"),
        views: ["NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "sidebar.docTemplate",
        to: "/lawyer-profile/doc-template",
        icon: require("@/assets/img/sidebar/requests.svg"),
        views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "sidebar.requests",
        to: "/lawyer-profile/requests",
        icon: require("@/assets/img/sidebar/requests.svg"),
        views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "sidebar.contracts",
        to: "/lawyer-profile/contracts",
        icon: require("@/assets/img/sidebar/contracts.svg"),
        views: ["LAWYER", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "sidebar.letterDocuments",
        to: "/lawyer-profile/letters-and-documents",
        icon: require("@/assets/img/sidebar/file-text.svg"),
        views: ["LAWYER", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "sidebar.orders",
        to: "/lawyer-profile/orders",
        icon: require("@/assets/img/sidebar/orders.svg"),
        views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
        children: [
          {
            titleKey: "orders.personalOrders",
            to: "/lawyer-profile/orders/list",
            views: ["LAWYER", "NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
          },
          {
            titleKey: "orders.contragentOrders",
            to: "/lawyer-profile/orders/contragents",
            views: ["NEW_DIRECTOR", "DIRECTOR", "ADMIN"],
          },
        ],
      },
      {
        titleKey: "sidebar.queryLog",
        to: "/lawyer-profile/notifications",
        icon: require("@/assets/img/sidebar/file-text.svg"),
        views: ["LAWYER", "DIRECTOR", "ADMIN"],
      },
      {
        titleKey: "trainCertificate",
        to: "/lawyer-profile/yumom-certificate",
        icon: require("@/assets/img/sidebar/bookmark.svg"),
        views: ["LAWYER", "DIRECTOR", "ADMIN"],
      },
    ],
  };
};

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
