import { createStore } from "vuex";
import lawyer from "./lawyer.module";
import contragent from "./contragent.module";
import post from "./post.module";
import deportament from "./deportament.module";
import region from "./region.module";
import sidebar from "./sidebar.module";
import order from "./order.module";
import appeals from "./appeals.module";
import request from "./request.module";
import employee from "./employee.module";
const store = createStore({
  modules: {
    lawyer,
    contragent,
    post,
    deportament,
    region,
    sidebar,
    order,
    employee,
    request,
    appeals,
  },
});

export function useStore() {
  return store;
}

export default store;
