<template>
  <span class="flex p-3 items-center justify-center">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.9199 22C14.1199 22 11.8399 19.73 11.8399 16.92C11.8399 14.12 14.1099 11.84 16.9199 11.84C19.7199 11.84 21.9999 14.11 21.9999 16.92C21.9999 19.73 19.7299 22 16.9199 22Z"
        fill="white"
      />
      <path
        opacity="0.4"
        d="M5.01988 2H8.93988C11.0099 2 12.0099 2.99999 11.9599 5.01999V8.94C12.0099 11.01 11.0099 12.01 8.93988 11.96H5.01988C2.99988 12 1.99988 11 1.99988 8.92999V5.01001C1.99988 3.00001 2.99988 2 5.01988 2Z"
        fill="white"
      />
      <path
        d="M9.00987 8.26001C8.69987 8.26001 8.40988 8.14 8.13988 7.94C8.38988 7.54 8.56988 7.09001 8.65988 6.60001H8.99988C9.40988 6.60001 9.74988 6.26001 9.74988 5.85001C9.74988 5.44001 9.40988 5.10001 8.99988 5.10001H8.00987C7.99987 5.10001 7.98988 5.10001 7.98988 5.10001H7.96988H7.70988C7.66988 4.72001 7.35988 4.42001 6.96988 4.42001C6.57988 4.42001 6.26987 4.72001 6.22987 5.10001H4.93988C4.52988 5.10001 4.18988 5.44001 4.18988 5.85001C4.18988 6.26001 4.52988 6.60001 4.93988 6.60001H7.10988C6.80988 7.56001 5.94987 8.26001 4.92987 8.26001C4.51987 8.26001 4.17987 8.60001 4.17987 9.01001C4.17987 9.42001 4.51987 9.76001 4.92987 9.76001C5.73987 9.76001 6.48988 9.49001 7.10988 9.04001C7.65988 9.50001 8.31988 9.76001 8.99988 9.76001C9.40988 9.76001 9.74988 9.42001 9.74988 9.01001C9.74988 8.60001 9.41987 8.26001 9.00987 8.26001Z"
        fill="white"
      />
      <path
        d="M8.99988 22.75C4.72988 22.75 1.24988 19.27 1.24988 15C1.24988 14.59 1.58988 14.25 1.99988 14.25C2.40988 14.25 2.74988 14.59 2.74988 15C2.74988 17.96 4.80988 20.44 7.57988 21.09L7.30988 20.64C7.09988 20.28 7.20989 19.82 7.56989 19.61C7.91989 19.4 8.38988 19.51 8.59988 19.87L9.64987 21.62C9.78987 21.85 9.78988 22.14 9.65988 22.37C9.51988 22.6 9.26988 22.75 8.99988 22.75Z"
        fill="white"
      />
      <path
        d="M21.9999 9.75C21.5899 9.75 21.2499 9.41 21.2499 9C21.2499 6.04 19.1899 3.56 16.4199 2.91L16.6899 3.35999C16.8999 3.71999 16.7899 4.18001 16.4299 4.39001C16.0799 4.60001 15.6099 4.49001 15.3999 4.13L14.3499 2.38C14.2099 2.15 14.2099 1.86 14.3399 1.63C14.4699 1.39 14.7199 1.25 14.9899 1.25C19.2599 1.25 22.7399 4.73 22.7399 9C22.7499 9.41 22.4099 9.75 21.9999 9.75Z"
        fill="white"
      />
      <path
        d="M19.7299 18.33L17.5899 14.06C17.4599 13.81 17.1999 13.64 16.9199 13.64C16.6399 13.64 16.3799 13.8 16.2499 14.05L14.1099 18.32C13.9199 18.69 14.0699 19.14 14.4499 19.33C14.5599 19.38 14.6699 19.41 14.7799 19.41C15.0499 19.41 15.3199 19.26 15.4499 19L15.6199 18.66H18.2099L18.3799 19C18.5699 19.37 19.0199 19.52 19.3899 19.34C19.7699 19.15 19.9199 18.7 19.7299 18.33ZM16.3799 17.16L16.9299 16.07L17.4799 17.16H16.3799Z"
        fill="white"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style>
</style>