import RequestService from "@/services/request.service";
import LegalAidService from "@/services/LegalAid.service";
import LawyerService from "@/services/lawyer/lawyer.service";
const state = () => {
  return {
    list: [],
    legalTotal: 0,
    legalLoading: false,
    total: 0,
    loading: false,
    requestTypeList: [],
    departmentList: [],
    uzbRegions: [],
    ownLicenseList: [],
    languagesList: [],
    contractPeriodList: [],
    childList: [],
  };
};

const actions = {
  async requestTemplateList({ commit, state }, data) {
    state.loading = true;
    try {
      const response = await RequestService.requestTemplateList(data);
      commit("SET_LEGAL_LIST", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      state.loading = false;
    }
  },
  async getRequestTypeList({ commit }) {
    try {
      const response = await RequestService.requestTypeList();
      commit("SET_REQUESTS", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async requestTypeByParentId({ commit }, id) {
    try {
      const response = await RequestService.requestTypeByParentId(id);
      commit("SET_REQUESTS_CHILDS", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async departmentAdliyaList({ commit }) {
    try {
      let res = await LegalAidService.departmentAdliyaList();
      commit("SET_DEPARTMENTS", res.data);
    } catch {
      commit("SET_DEPARTMENTS", []);
    }
  },
  async getUzbRegions({ commit }) {
    this.loading = true;
    try {
      let res = await LawyerService.RegionList();
      if (res.data) {
        commit("SET_UZB_REGIONS", res.data[0].children);
      }
    } catch {
      commit("SET_UZB_REGIONS", []);
    }
  },
  async directoryLicenseType({ commit }) {
    try {
      let res = await LawyerService.listOwnLicenseType();
      if (res.data) {
        commit("SET_OWN_LICENSE_LIST", res.data);
      }
    } catch {
      commit("SET_OWN_LICENSE_LIST", []);
    }
  },
  async directoryLanguageList({ commit }) {
    try {
      let res = await LegalAidService.directoryLanguageList();
      if (res.data) {
        commit("SET_LANGUAGES_LIST", res.data);
      }
    } catch {
      commit("SET_LANGUAGES_LIST", []);
    }
  },
  async getmonthOrYEarList({ commit }) {
    try {
      let res = await LegalAidService.monthOrYEarList();
      if (res.data) {
        commit("SET_MONTH_OR_YEAR_LIST", res.data);
      }
    } catch {
      commit("SET_MONTH_OR_YEAR_LIST", []);
    }
  },
};

const getters = {};

const mutations = {
  SET_LEGAL_LIST(state, payload) {
    state.list = payload?.list || [];
    state.total = payload.total || 0;
  },
  SET_REQUESTS(state, payload) {
    state.requestTypeList = payload || [];
  },
  SET_REQUESTS_CHILDS(state, payload) {
    state.childList = payload || [];
  },
  SET_DEPARTMENTS(state, payload) {
    state.departmentList = payload || [];
  },
  SET_UZB_REGIONS(state, payload) {
    payload.sort((a, b) => {
      return a.nameLt.localeCompare(b.nameLt);
    });
    state.uzbRegions = payload || [];
  },
  SET_OWN_LICENSE_LIST(state, payload) {
    state.ownLicenseList = payload || [];
  },
  SET_LANGUAGES_LIST(state, payload) {
    state.languagesList = payload?.list || [];
  },
  SET_MONTH_OR_YEAR_LIST(state, payload) {
    state.contractPeriodList = payload?.list || [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
