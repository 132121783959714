export const locale = {
  // actions
  name: {
    lt: "Nomi (lotin)",
    ru: "Название (русский)",
    uz: "Номи (кирилл)",
    en: "Name (english)",
  },
  lang: {
    russian: "Русский",
    uzbekLatin: "O‘zbek (Lotin)",
    uzbekKrill: "Ўзбек (кирилл)",
    english: "English",
  },
  times: {
    month: "Oy",
    day: "Kun",
    week: "Hafta",
  },
  months: {
    title: "Oylar",
    january: "Yanvar",
    february: "Fevral",
    march: "Mart",
    april: "Aprel",
    may: "May",
    june: "Iyun",
    july: "Iyul",
    august: "Avgust",
    september: "Sentabr",
    october: "Oktabr",
    november: "Noyabr",
    december: "Dekabr",
  },
  /////////\\\\\\STATUS\\\\\\\\\\\\///////
  status: {
    CREATED: "Yaratilgan",
    DRAFT: "Qoralama",
    PRINTED: "Chop etilgan",
    status: "Holati",
    SENT: "Yuborilgan",
    active: "faol",
    inactive: "faol emas",
    notFound: "Aniqlanmagan",
    SEND: "Yuborildi",
    SEEN: "Ko'rib chiqildi",
    CANCELLED: "Bekor qilindi",
    ACCEPTED: "Suhbat jarayonida",
    MEETING: "Suhbat belgilandi",
    MEETING_PROCESS: "Suhbat jarayonida",
    PASSED: "Suhbatdan o'tdi",
    FAILED: "Suhbatdan yiqildi",
    ABSENT: "Suhbatga kelmadi",
    cancelled: "Bekor qilingan",
    send: "Yuborilgan",
    sendToSign: "Imzolashga yuborilgan",
    signed: "Imzolangan",
    SIGNED: "Imzolangan",
    accepted: "Qabul qilingan",
    signedDeportament: "Boshqarma imzoladi",
    successInput: "Muvaffaqiyatli kiritildi",
    COMPLETED: "Yakunlandi",
    REJECTED: "Rad etildi",
    PAYMENT: "To'lovingiz kutilmoqda",
    CLOSED: "Yakunlandi",
    CANCELLED_BY_DIRECTOR: "Boshqarma  tomonidan bekor qilindi",
    CANCELLED_BY_LAWYER: "Advokat tomonidan bekor qilindi",
  },

  winning: {
    card1: {
      title: "Murojaat yuborish",
      text: "Nogironligi bo'lgan shaxslar tomonidan",
    },
    card2: {
      title: "Advokatlar statistikasi",
      text: "Barcha advokatlar statistikasi va foydali ma'lumotlar",
    },
    card3: {
      title: "Advokatlar reytingi",
      text: "Advokatlarning alohida reytingi va ular bilan ishlash",
    },
  },
  views: "Ko'rildi",
  categorieslist: {
    item1: {
      title: "Jinoyat - ma'muriy",
      text: "Oʼzbekiston Respublikasining Jinoyat, Jinoyat-protsessual va Maʼmuriy javobgarlik toʼgʼrisidagi kodekslariga oʼzgartish va qoʼshimchalar kiritish haqida",
    },
    item2: {
      title: "Fuqarolik - Iqtisodiy",
      text: "Oʼzbekiston Respublikasining Jinoyat, Jinoyat-protsessual va Maʼmuriy javobgarlik toʼgʼrisidagi kodekslariga oʼzgartish va qoʼshimchalar kiritish haqida",
    },
  },
  contract: {
    signing: "Shartnoma imzolash",
    numberDate: "Shartnoma raqami va sanasi",
    all: "Barcha shartnomalar",
    sentLawyer: "Imzolashingiz kutilmoqda",
    ACCEPTED: "Imzolangan",
    CANCELLED: "Rad qilingan",
  },
  editProfile: {
    title: "Profilni tahrirlash",
    name: "Ism",
    surname: "Familiya",
    patronymic: "Otasining ismi",
    phone: "Telefon raqami",
    email: "Elektron pochta",
    picture: "Rasm",
    address: "Manzili",
  },
  dialog: {
    yes: "Ha",
    no: "Yo'q",
  },
  success: {
    editeProfile: "Profil muvofaqqiyatli tahrirlandi",
    delete: "Muvaffaqiyatli o'chirildi",
    save: "Muvaffaqiyatli saqlandi",
    cancel: "Muvaffaqiyatli bekor qilindi",
    completed: "Muvaffaqiyatli yakunlandi",
  },
  orders: {
    all: "Orderlar",
    draft: "Qoralama",
    orderInfo: "Order haqida ma'lumot",
    electronOrder: "Elektron shakldagi orderlar",
    documenttalOrder: "Qog'oz shakldagi orderlar",
    groundsForGiven: "Order berishga asos bo'lgan hujjat",
    viewPeperForm: "Qog'oz shaklida ko'rish",
    sureCancelOrder: "Orderni bekor qilmoqchimisiz?",
    cancelOrder: "Orderni bekor qilish",
    createEOrder: "e-Order yaratish",
    createOrder: "Order yaratish",
    list: "Orderlar ro'yxati",
    countsAll: "Jami orderlar soni",
    sends: "Yuborilganlar",
    cancelleds: "Bekor qilinganlar",
    snetLate: "Kechikib kiritilgan",
    electron: "Elektron",
    paper: "Qog'oz",
    youNorCreateYet: "Siz hali bunday order yaratmadingiz",
    viewPpaperForm: "Qog'oz shaklida ko'rish",
    viewOrder: "Orderni ko'rish",
    addToReportForm: "Hisobot shakliga qo'shish",
    sendOrder: "Orderni yuborish",
    createElectronOrder: "Elektron order yaratish",
    createPaperOrder: "Qog'oz order yaratish",
    whatOrderWho: "Advokat tomonidan ishni olib borishga ORDER",
    selectedDocumentNumber: "Tanlangan hujjatlar raqami",
    selectedDocumentDate:
      "Order berishga asos bo'lgan hujjat rasmiylashtirilgan sana",
    personSelectTitle:
      "Ishonch bildiruvchi ( himoya ostidagi ) shaxs haqidagi ma'lumot",
    legalEntity: "Yuridik shaxs",
    individual: "Jismoniy shaxs",
    orderDate: "Order sanasi",
    inputFileOrder: "Qog'oz shakldagi order faylini yuklang",
    inputPaperFormOrder: "Qog'oz shaklida order kiritish",
    legalAidWorkType: "Yuridik yordam ko'rsatiladigan ish turi",
    saveToDrafts: "Qoralamalarga saqlash",
    orderType: "Order turi",
    sureDelete: "Siz rostdan ham ushbu orderni o'chirmoqchimisiz?",
    personalOrders: "Shaxsiy orderlar",
    contragentOrders: "Tuzilmaning orderlari",
    createdDate: "Order yaratilgan sana",
    createFeature: "Order yaratishga ruhsat",
    cancelCreatePermission: "Order yaratishga ruhsatni bekor qilish",
    canCreatePermission: "Order yaratishga ruhsat berish",
    limitedByContragent: "Order yaratish tuzilma tomonidan cheklab qo'yilgan",
  },
  titles: {
    personalInfo: "Shaxsiy ma'lumotlar",
    lawyerLicenseInfos: "Advokat litsenziya ma'lumotlari",
    lawyerCertificateInfos: "Advokat guvohnomasi ma'lumotlari",
    lawyerContragentInfos: "Advokat tuzilma ma'lumotlari",
    litcenseType: "Litsenziya ixtisosligi",
    licensingAuthority: "Litsenziya beruvchi organ",
    certificatingAuthority: "Guvohnoma beruvchi organ",
    workExperienceAndAchievementsOfTheLawyer:
      "Аdvokatning ish tajribasi va yutuqlari",
    mainLinesOfActivity: "Asosiy faoliyat yo‘nalishlari",
  },
  sidebar: {
    controlPanel: "Boshqaruv paneli",
    myWorks: "Mening ishlarim",
    requests: "Arizalar",
    contracts: "Shartnomalar",
    docTemplate: "Xat va hujjat shablonlari",
    orders: "Orderlar",
    statisticInfo: "Statistika",
    profile: "Profil",
    letterDocuments: "Xatlar va hujjatlar",
    control: "Boshqaruv",
    queryLog: "So'rovlar jurnali",
  },
  lawyer: {
    FISH: "Advokat F.I.SH",
  },
  request: {
    list: "Arizalar ro'yxati",
    create: "Ariza yaratish",
    allCount: "Jami arizalar soni",
    inProgress: "Jarayondagi arizalar",
    accepteds: "Qabul qilinganlar",
    cancels: "Bekor qilinganlar",
    all: "Barcha arizalar",
    progresses: "Jarayondagilar",
    createDate: "Ariza yaratilgan sana",
    number: "Ariza raqami",
    type: "Ariza turi",
    status: "Ariza holati",
    reason: "Sababi",
    view: "Arizani ko'rish",
    fileForPay:
      "Advokatlik guvohnomasini qayta rasmiylashtirish uchun asos bo‘lgan tasdiqlovchi hujjat",
    requestForSendLegalAid: "Yuridik yordam ko'rsatishga ariza",
    replaceCertificate: "Guvohnomani almashtirish bo'yicha ariza",
    replaceLicense: "Litsenziyani almashtirish bo'yicha ariza",
    statusStoping: "Advokat maqomni to'xtatib turish bo’yicha ariza",
    forFreeHelp: "Bepul yordam ko'rsatish bo'yicha ariza",
    history: "Ariza tarixi",
    viewHistory: "Ariza tarixini ko'rish",
    imgForLawyerCertificate: "Advokatlik guvohnomasi uchun rasm",
    cancelledReason: "Arizani rad qilish sababi",
    imageReason:
      "Agar 3x4 o‘lchamdagi rangli fotosuratingizni yuklamasangiz, Davlat personallashtirish markazi maʼlumotlar bazasidan kelgan rasmingiz guvohnomaga qo‘yiladi.",
    getBySelf: "Olib ketish",
    getByMail: "Pochta orqali yuborish",
    reSend: "Qayta yuborish",
    statuses: {
      SEEN: "Ko'rib chiqilmoqda",
      SEND: "Yuborilgan",
      DRAFT: "Qoralama",
      CANCELLED: "Rad etilgan",
      ACCEPTED: "Qabul qilingan",
      PAYMENT: "To'lovingiz kutilmoqda",
    },
  },
  workBeginDate: "Ish boshlagan sana",

  lawyerStatuses: {
    licenceStop: "Litsenziya to'xtatilgan",
    licenceActive: "Litsenziya faol",
    licenceEnd: "Litsenziya tugatilgan",
    statusStop: "Advokatlik maqomi to'xtatilgan",
    status: "Advokatlik holati",
    licenseStatus: "Litsenziya holati",
  },

  user: {
    user: "Foydalanuvchi",
    fullName: "F.I.SH",
    jshshr: "Shahsiy identifikatsiya raqami (JSHSHR)",
    address: "Manzili",
    personalInfo: "Foydaluvchi  shaxsiy ma'lumotlari",
    appeals: "Murojaatlar",
    firstName: "Ism",
    lastName: "Familiya",
    middleName: "Otasining ismi",
  },
  appeals: {
    appeal: "Murojaat",
    appeals: "Murojaatlar",
    myAppeals: "Murojaatlarim",
    allAppeals: "Barcha murojaatlar",
    acceptedAppeals: "Qabul qilinganlar",
    create: "Murojaat yaratish",
    region: "Hudud",
    type: "Murojaat turi",
    commentWork: "Ishning qisqacha mazmuni",
    commentWork2: "Ishning aniq mazmuni",
    dontPermission: "Murojaatni yaratishga ruhsat yo‘q.",
    youNotInvalide:
      "Ushbu bo‘lim nogironligi bo‘lgan shaxslarga ixtiyoriy bepul yordam ko‘rsatishga (pro bono) ko‘maklashish doirasida yaratilgan. Sizning JSHSHIR orqali nogironligi bo‘lgan shaxs ekanligingiz tasdiqlanmadi.",
    byAppealsInfos: "Murojaat haqida ma'lumot",
    empty: "Murojaatlar mavjud emas",
    regionAndPhone: "Belgilangan hudud va telefon raqam",
    appealSender: "Murojaat yuborgan shaxs",
    accept: "Murojaatni qabul qilish",
    sureAccept:
      "Mazkur murojaat muallifiga bepul yuridik yordam ko‘rsatmoqchimisiz?",
    accepted: "Murojaat qabul qilindi",
    createEAppeals: "e-Murojaat yaratish",
    eAppeals: "e-Murojaatlar",
    contentWork: "Murojaat mazmuni",
    send: "Murojaatni yuborish",
    fieldOf: "Murojaat sohasi",
    dateSend: "Yuborilgan sana",
    addFile: "Shu murojaatga tegishli qo'shimcha hujjatni yuklashingiz mumkin",
    attechedLawyer: "Biriktirilgan advokat",
    sureConfirm: "Murojaat yuborish qoidalari bilan tanishdim",
    view: "Murojaatni ko'rish",
    selectedLawyer: "Biriktirilgan advokat",
    viewUploadFile: "Yuklangan faylni ko'rish",
    complation: "Murojaatni yakunlash",
    aboutCompleted: "Natijasi",
    certificateSendAddress: "Advokatlik guvohnomasini yetkazib berish manzili",
    labelList:
      "Nogironligi boʼlgan shaxslarning ixtiyoriy bepul yuridik yordam (pro bono) olish boʼyicha murojaatlari",
  },
  certificate: {
    changeNeason: "Guvohnoma olish/yangilash sababi",
  },
  licenseFileShow: "Litsenziya faylini ko'rish",
  uploadFile: "Fayl yuklash",
  oneIIdAuth: "OneID autentifikatsiyasi",
  oneIIdAuthDesc:
    "OneID autentifikatsiyasi orqali tizimga kirish jarayoni boshlandi. Iltimos, Sahifa yuklanishi tugallanishini kuting...",
  id: "ID",
  cityTaoun: "Shahar/Tuman",
  applicant: "Talabgor",
  userRequests: "Foydalanuvchi murojaatlari",

  legislativeNews: "Qonunchilikdagi yangiliklar",
  yes: "Ha",
  documentType: "Hujjat turi",
  drafts: "Qoralamalar",
  electronSigning: "Elektron imzolash",
  fieldMandatory: "Bu maydon to'ldirilishi shart",
  listEmpty: "Ro'yxat bo'sh",
  requireTimeRange: "Kerakli vaqt oralig'i",
  profile: "Profil",
  systemRunningTestMode: "Tizim test rejimida ishlamoqda",
  shortAboutWork: "Qisqacha ish haqida",
  isProvided: "Yuridik yordam ko'rsatiluvchi",
  awaitingYourReply: "Sizning javobingiz kutilmoqda",
  cancelingRequest: "So'rovni bekor qilish",
  acceptingRequest: "So'rovni qabul qilish",
  acceptedRequest: "So'rov qabul qilindi",
  canceledRequest: "So'rov bekor qilindi",
  paymnetApproved: "To'lovga tasdiqlandi",
  specialty: "Mutaxassislik",
  sendFiltesNotFull: "Fayllar to'liq yuklanmagan",
  closedRequest: "So'rov yopildi",
  sentRepot: "Hisobot yuborildi",
  reportNotClose: "Hisobot yopilmagan",
  notifications: "Bildirishnomalar",
  certificateRequest: "Guvohnoma uchun ariza yuborish",
  order: "Order",
  selectfile: "Faylni joylang",
  drophere: "Yoki bu yerga",
  sendorders: "Yuborilgan orderlarlar",
  createOrder: "Yangi order yaratish",
  hour: "soat",
  onlinerequest: "ONLAYN QABULGA YOZILISH",
  share: "Ulashish",
  contragentWorkers: "Tashkilot hodimlari",
  sureDeleteLawyer: "Rostdan ham ushbu hodimni ishdan bo'shatmoqchimisiz?",
  sureSubmitLawyer: "Rostdan ham ushbu hodimni ishga olasizmi?",
  successMuve: "Hodim muvofaqqiyatli bo'shatildi",
  toWhom: "Kim uchun",
  protectedPerson: "Himoya ostidagi shaxs",
  contractDate: "Shartnoma sanasi",
  contractPeriod: "Shartnoma muddati",
  successSubmit: "Hodim muvofaqqiyatli ishga qabul qilindi",
  forum: "Forum",

  Name: "Nomi",
  fonLawyers: "Advokatlarga tegishli qarorlar",
  type: "Turi",
  mainName: "O'zbekiston Respublikasi",
  mainNameStrong: "Adliya Vazirligi",
  contragentFullName: "Advokatlik tuzilmasi to'liq nomi",
  contragentName: "Advokatlik tuzilmasi nomi",
  projectName: "Advokatlarni avtomat tanlash tizimi",
  lawyerCertificate: "Advokatlik guvohnomasi",
  username: "Foydalanuvchi nomi",
  firstName: "Ismi",
  lastName: "Familyasi",
  parentName: "Otasining ismi",
  phone: "Telefon raqami",
  numberDoc: "Hujjat raqami",
  email: "Elektron pochta manzili",
  password: "Parol",
  signUp: "Ro'yhatdan o'tish",
  lawyerrester: "Advokatlar reesteri",
  requesite: "Rekvizit",
  comment: "Izoh",
  countWorkers: "Hodimlari soni",
  noteNumber: "Qayd raqami",
  courseName: "Kurs nomi",
  trainCertificate: "Malaka sertifikati",
  WARNING: "Sertifikat tugash muddatiga yaqin",
  DANGER: "Sertifikat tugash muddatida",
  EXPIRED: "Sertifikat muddati tugatilgan",
  from: "dan",
  until: "gacha",
  coursSize: "Kurs davomiyligi",
  signed: "Imzolangan",
  nextTrain: "Navbatdagi malaka oshirish",
  didntHaveCertificate: "Sertifikat olmaganlar",
  providingLegal: "Yuridik yordam",
  businessLanguage: "Ish yuritish tili",
  areaOfOperation: "Ish yuritish hududi",
  formingAnAplication: "Arizani shakllantirish",
  month: "oy",
  dutyScheduleHeader: "{region}da {year}-yil {month} oyida jinoyat ishlari bo‘yicha davlat hisobidan yuridik yordam ko‘rsatuvchi advokatlarning NAVBATCHILIK JADVALI",
  requests: "Arizalar",
  requestType: "Ariza turi",
  contracts: "Shartnomalar",
  signContract: "Shartnoma imzolash",
  cancelingContract: "Shartnomani rad etish",
  viewContract: "Shartnomani ko'rish",
  laawyerAttorneyNumber: "Advokat hisob raqami",
  MFO: "MFO",
  successSign: "Shartnoma muvofaqqiyatli imzolandi",
  contractWasSigned: "Shartnoma imzolandi",
  sentForSignature: "Imzolash uchun yuborildi",
  sentToDdeportment: "Boshqarmaga yuborildi",
  //  actions
  errorReq: "Bu maydon bo'sh bo'lishi mumkin emas!",
  errorEmail: "Email manzil xato kiriligan",
  errorField: "Ma'lumot xato to'ldirilgan",
  search: "Qidiruv",
  deleteIt: "Rostan ham o'chirmoqchimisz?",
  successSave: "Muvaffaqiyatli saqlandi",
  actions: "Amallar",
  notFound: "Ma'lumot topilmadi",
  save: "Saqlash",
  code: "Kodi",
  home: "Bosh sahifa",
  add: "Qo'shish",
  addEmployee: "Hodim qo'shish",
  edit: "Tahrirlash",
  pdf: "PDF yuklash",
  prev: "Ortga",
  cancel: "Bekor qilish",
  delete: "O'chirish",
  choose: "Tanlash",
  choosen: "Tanlangan",
  noChoosen: "Tanlanmagan",
  clikForSelect: "Tanlash uchun bosing",
  selectAll: "Hammasini tanlash",
  selectContragent: "Kontragentni tanlash",
  sign: "Imzolash",
  dutySchedule: "Navbatchilik jadvali",
  monday: "Dushanba",
  mon: "DSH",
  tuesday: "Seshanba",
  tue: "SE",
  wednesday: "Chorshanba",
  wed: "CHR",
  thursday: "Payshanba",
  thu: "PA",
  friday: "Juma",
  fri: "JU",
  saturday: "Shanba",
  sat: "SHA",
  sunday: "Yakshanba",
  sun: "YAK",
  january: "Yanvar",
  february: "Fevral",
  march: "Mart",
  april: "Aprel",
  may: "May",
  june: "Iyun",
  july: "Iyul",
  august: "Avgust",
  september: "Sentyabr",
  october: "Oktyabr",
  november: "Noyabr",
  december: "Dekabr",
  download: "Yuklab olish",
  dutyDays: "Navbatchilik kunlari",
  dutyMonth: "Navbatchilik oyi",
  rangeOfTerritories: "Xududlar doirasi",
  phoneNumber: "Telefon raqami",
  lawyerFullName: "Advokat F.I.O.",
  address: {
    region: "Viloyat",
    physical: "Jismoniy joylashuvi",
    location: "Geolokatsiya",
    legal: "Yuridik manzil",
    phone: "Ish telefoni",
    mobilePhone: "Shaxsiy telefoni",
    email: "Elektron pochta manzili",
    physicalRegionId: "Joylashgan joyi",
    physicalAddress: "Manzili",
    legalRegionId: "Yuridik manzili (joylashgan joyi)",
    legalAddress: "Manzili",
    place: "Ko'cha va uy",
    live: "Yashash manzili",
    fax: "Faks raqami (mavjud bo'lsa)",
  },
  readMored: "Batafsil...",
  lawyersConrtagent: "Advokatlik tuzilmasi hodimlari",
  confirmCreateContragent:
    "Ushbu advokatlik tuzulmasini yaratishni hohlaysizmi",
  confirmUpdateCurator:
    "Ushbu advokatlik tuzulmasi rahbarini o'zgartirishni hohlaysizmi",
  print: "Chop qilish",
  allRestore: "Barcha ma'lumotlarni yuklash",
  courseTotal: "Boshlangan sana / tugash sana",
  evoluation: "Baholash",
  statusSituation: "Holat",
  // form fields
  seriaAndNumber: "Seriya va raqami",
  title: "Seriasi",
  depType: "Tashkilot turi",
  regionType: "Hudud tartibi",
  orderNum: "Order raqami",
  showMap: "Xaritadan ko'rsatish",
  prefix: "Prefix",
  innLawyer: "Identifikatsiya raqami (JSHSHIR)",
  innContragent: "Identifikatsiya raqami (STIR)",
  dirinn: "Rahbar INN",
  dirfirstName: "Rahbarning ismi",
  dirlastName: "Rahbarning familyasi",
  dirparentName: "Rahbarning otasining ismi",
  pochtaIndex: "Pochta indeksi",
  passport: "Pasport",
  passportNumber: "Pasport raqami",
  passportGivenDate: "Pasport berilgan sana",
  passportGivenWho: "Pasport berilgan joy",
  passportEndDate: "Pasport amal qilish muddati",
  birthday: "Tug'ilgan sanasi",
  birthyear: "Tug'ilgan yil",
  deadyear: "Vafot etgan yili",
  onlyYear: "Faqat yil",
  year: "Yil",
  mobilePhone: "Shaxsiy raqami",
  birthCountry: "Tug'ilgan davlati",
  birthRegion: "Tug'ilgan viloyati",
  birthDistrict: "Tug'ilgan tumani",
  fullName: "To'liq ismi",
  birthPlace: "Tug'ilgan joyi",
  passportDetails: "Pasport ma'lumotlari",
  additional: "Qo'shimcha ma'lumotlar",
  yearFormat: "yyyy",
  dateFormat: "dd-mm-yyyy",
  dateFormat2: "yyyy-mm-dd",
  date: "Sanasi",
  photo: "Rasm",
  photoType: "Rasm turi",
  beginWorkDate: "Advokatlik guvohnomasi berilgan sanasi",
  endWorkDatel: "Advokatlik guvohnomasi tugash sanasi",
  endWorkDate: "Ishdan bo'shatish sanasi",
  givenDate: "Berilgan sana",
  endYear: "Bitiruv yili",
  whereWork: "Ishlagan joyi",
  givenYear: "Berilgan yili",
  dead: "Vafot etgan",
  noInformation: "Ma'lumot yo'q",
  reason: "Sababi",
  workType: "Qayerda kim bo'lib ishlaydi",
  whoGiven: "Kim tomonidan berilgan",
  militaryRankNumber: "Harbiy unvon raqami",
  yearOfMilitaryService: "Xizmat o'tagan yil",
  division: "Bo'lim",
  groups: "Guruh",
  class: "Sinf",
  subClass: "Kichik sinf",
  ifut: "IFUT",
  okonx: "XXTUT",
  pnfl: "PNFL",
  shortName: "Qisqacha nomi (mavjud bo'lsa)",
  byPassportNumber: "Pasport seriasi",
  directorPassportSerial: "Rahbar pasport seriasi",
  directorPassportNumber: "Rahbar pasport raqami",
  organizedDate: "Tashkil etilgan sana",
  orderFamilyType: "Tartib raqami",
  certificates: "Guvohnomalar",
  certificateNumber: "Advokatlik guvohnoma raqami",
  certificateGivenDate: "Advokatlik guvohnomasi berilgan sanasi",
  qr_code: "QR-Code",
  check: "Tekshirish",
  director: "Rahbar",
  contragentExistError: "Ushbu advokatlik tuzilmasi avvaldan mavjud",
  contragentExistErrorS: "Ushbu advokatlik tuzilmasi ro'yxatdan o'tmagan",
  checkLawyerFromBase: "Advokatlik tuzilmasini tizimdan tekshirish",
  saveContragent: "Advokatlik tuzilmasini tizimga qo'shish",
  saveLawyer: "Advokatlik tuzilmasi rahbarini biriktirish",
  setCurator: "Rahbar",
  chooseLicenseFile: "Advokatlik litsenziyasini kiritish",
  addFile: "File qo'shish",
  fileError: "File kiriting",
  journal: "Jurnal",
  licenses: "Litsenziyalar",
  licenseTitle: `O'zbekiston respublikasi`,
  licenseTitle1: `adliya vazirligi`,
  "Advokat qo'shish": "Advokat qo'shish",
  "Birlashma qo'shish": "Birlashma qo'shish",
  "Advokat birlashmasi tahrirlash": "Advokat birlashmasi tahrirlash",
  videos: "Videolar",
  tutorials: "Qo'llanmalar",
  certificateNumber1: "Guvohnoma",
  lawyerOf: "Advokati",
  License: "Litsenziya",
  speciality: "Mutahasisligi",
  licenseGivenWho: "Litsenziya berilgan tashkilot",
  contragentInfo: "Tanlangan advokatlik tuzilmasi",
  allLawyers: "Jami Advokatlar",
  lawyers: "Advokatlar",
  allMaleLawyers: "Erkaklar soni",
  allFemaleLawyers: "Ayollar soni",
  addPassport: "Pasport yuklash",
  getPassport: "Pasportni ko'rish",
  startWork: "Ishga kirgan sana",
  endWork: "Ishdan bo'shagan sana",
  workPlace: "Ish joyi",
  position: "Lavozimi",
  andOther: "Boshqa",
  getPassportError: "Pasport biriktirilmagan",
  lawyerStatus: "Advokatlar maqomi",
  minjustorgans: "Xududiy adliya organlari",
  editLicenseStatus: "Advokat maqomini tahrirlash",
  dutyScheduleTitle: "Eslatmalar",
  dutyScheduleTitle1:
    "1. Mazkur Navbatchilik jadvali elektron shaklda tasodifiy tanlash yo‘li bilan shakllantirilgan bo‘lib, navbatchilik jadvalini shakllantirishda inson omili mavjud emas. ",
  dutyScheduleTitle2:
    "2. Surishtiruvchi, tergovchi, prokuror yoki sud qonunchilikka asosan belgilangan navbatchi advokatlardan birini ishga jalb qilishi mumkin. ",
  dutyScheduleTitle3:
    "3. Hurmatli fuqarolar, mazkur navbatchilik jadvali asosida faqat surishtiruvchi, tergovchi, prokuror yoki sud advokatni jalb qilishi mumkin. Navbatchilik jadvalidagi advokatga bevosita aloqaga chiqib, ularni bezovta qilmasligingizni so‘raymiz.",
  ACTIVE: "Litsenziya amalda",
  wokEnd: "Amal qilish muddati",
  INACTIVE: "Litsenziya to'xtatilgan",
  PermanentlyInActive: "Litsenziya maqomi butunlay to'xtatilgan",
  TemporarilyInActive: "Litsenziya maqomi vaqtinchalik to'xtatilgan",
  null: "Not Found",
  archiveList: "Litsenziyaga tegishli hujjatlar",
  allList: "Barcha litsenziyalar tarixi",
  canAddMultipleFiles: "Bir nechta fileni bir vaqtda yuklash munkin",
  beginDate: "Berilgan sana",
  endDate: "Tugash sana",
  permissions: {
    userManagement: "Foydalanuvchilarni boshqarish",
    permissions: "Ruhsatlar",
    roles: "Rollar",
  },
  // end actions
  // page titles
  // References
  references: "Ma'lumotlar",
  //
  academicDegree: "Akademik daraja",
  academicDegreeAdd: "Akademik daraja qo'shish",
  academicDegreeUpdate: "Akademik darajani tahrirlash",
  //
  academicTitle: "Akademik unvon",
  academicTitleAdd: "Akademik unvon qo'shish",
  academicTitleUpdate: "Akademik unvonni tahrirlash",
  //
  award: "Mukofot",
  awardAdd: "Mukofot qo'shish",
  awardUpdate: "Mukofotni tahrirlash",
  //
  color: "Rang",
  colorAdd: "Rang qo'shish",
  colorUpdate: "Rangni tahrirlash",
  //
  CandidateDoc: "Talabgor hujjati",
  CandidateDocAdd: "Talabgor hujjati qo'shish",
  CandidateDocUpdate: "Talabgor hujjati tahrirlash",
  //
  educationalInstitution: "Ta'lim muassasasi",
  educationalInstitutionAdd: "Ta'lim muassasasi qo'shish",
  educationalInstitutionUpdate: "Ta'lim muassasasini tahrirlash",
  //
  education: "Ma'lumoti",
  educationAdd: "Ma'lumotini qo'shish",
  educationUpdate: "Ma'lumotini tahrirlash",
  //
  educationType: "Ta'lim muassasasi turi",
  educationTypeAdd: "Ta'lim muassasasi turini qo'shish",
  educationTypeUpdate: "Ta'lim muassasasini turini tahrirlash",
  //
  familyMember: "Oila a'zoligi",
  familyMemberAdd: "Oila a'zoligi qo'shish",
  familyMemberUpdate: "Oila a'zoligini tahrirlash",
  //
  gender: "Jinsi",
  genderAdd: "Jins qo'shish",
  genderUpdate: "Jinsni tahrirlash",
  //
  language: "Til",
  languageAdd: "Til qo'shish",
  languageUpdate: "Til tahrirlash",
  //
  languageStatus: "Til holati",
  languageStatusAdd: "Til holatini qo'shish",
  languageStatusUpdate: "Til holatini tahrirlash",
  //
  militaryRank: "Harbiy unvon",
  militaryRankAdd: "Harbiy unvonni qo'shish",
  militaryRankUpdate: "Harbiy unvonni tahrirlash",
  //
  partyMembership: "Partiyaviyligi",
  partyMembershipAdd: "Partiyaviyligini qo'shish",
  partyMembershipUpdate: "Partiyaviyligini tahrirlash",
  //
  passportSerial: "Pasport Seriasi",
  passportSerialAdd: "Pasport Seriasini qo'shish",
  passportSerialUpdate: "Pasport Seriasini tahrirlash",
  //
  rectal: "Harbiy komissiya",
  rectalAdd: "Harbiy komissiyani qo'shish",
  rectalUpdate: "Harbiy komissiyani tahrirlash",
  //
  specDiploma: "Mutaxassislik",
  specDiplomaAdd: "Mutaxassislikni qo'shish",
  specDiplomaUpdate: "Mutaxassislikni tahrirlash",
  //
  typePosition: "Lavozim toifasi",
  typePositionAdd: "Lavozim toifasini qo'shish",
  typePositionUpdate: "Lavozim toifasini tahrirlash",
  //
  region: "Hudud",
  departments: "Advokatlik tuzilmalari",
  legislation: "Qonunchilik",
  news: "Yangiliklar",
  soato: "SOATO",
  pc: "PC",
  SP_ID: "SP",

  //
  administartion: "Boshqarma",
  department: "Tashkilot",
  departmentAdd: "Tashkilotni qo'shish",
  departmentUpdate: "Tashkilotni tahrirlash",
  //
  departmentType: "Tashkilot turi",
  departmentTypeAdd: "Tashkilot turini qo'shish",
  departmentTypeUpdate: "Tashkilot turini tahrirlash",
  //
  departmentLocationType: "Tashkilot joylashuv turi",
  departmentLocationTypeAdd: "Tashkilot joylashuv turini qo'shish",
  departmentLocationTypeUpdate: "Tashkilot joylashuv turini tahrirlash",
  //
  workMode: "Ish turi",
  workModeAdd: "Ish turini qo'shish",
  workModeUpdate: "Ish turini tahrirlash",
  yuridik: "Yuridik",
  //
  employee: "Foydalanuvchi",
  employeeAdd: "Foydalanuvchi qo'shish ",
  employeeUpdate: "Foydalanuvchini tahrirlash ",
  //
  contragent: "Advokatlik tuzilmasi",
  contragentAdd: "Advokatlik tuzilmasi qo'shish",
  contragentUpdate: "Advokatlik tuzilmasi tahrirlash",
  //
  maritalStatus: "Oilaviy holati",
  maritalStatusAdd: "Oilaviy holati qo'shish",
  maritalStatusUpdate: "Oilaviy holatini tahrirlash",
  //
  passportType: "Pasport turi",
  passportTypeAdd: "Pasport turi qo'shish",
  passportTypeUpdate: "Pasport turini tahrirlash",
  //
  licenseType: "Litsenziya ixtisosligi",
  licenseTypeAdd: "Litsenziya ixtisosligi qo'shish",
  licenseTypeUpdate: "Litsenziya ixtisosligi tahrirlash",
  //
  licenseSerial: "Litsenziya seriyasi",
  licenseSerialAdd: "Litsenziya seriyasi qo'shish",
  licenseSerialUpdate: "Litsenziya seriyasini tahrirlash",
  //
  licenseNumber: "Litsenziya raqami",
  licenseGivenDate: "Litsenziya berilgan sana",
  licenseEndDate: "Litsenziya tugash sana",
  licenseSerialAndNumber: "Litsenziya seriya va raqami",

  //
  ownershipType: "Mulkchilik turi",
  ownershipTypeAdd: "Mulkchilik turi qo'shish",
  ownershipTypeUpdate: "Mulkchilik turini tahrirlash",
  //
  lawyerAdd: "Advokatlar qo'shish",
  lawyerUpdate: "Advokatlarni tahrirlash",
  //
  typesOfEconomicActivity: "Iqtisodiy faoliyat turlari",
  typesOfEconomicActivityAdd: "Iqtisodiy faoliyat turini qo'shish",
  typesOfEconomicActivityUpdate: "Iqtisodiy faoliyat turini tahrirlash",
  //
  lawOffices: "Advokatlik tuzulmasi turi",
  lawOfficesAdd: "Advokatlik tuzulmasi turini qo'shish",
  lawOfficesUpDate: "Advokatlik tuzulmasi turini tahrirlash",
  //
  lawyerProfile: "Advokat Profili",
  //
  nationality: "Millati",
  nationalityAdd: "Millat qo'shish",
  nationalityUpdate: "Millatni tahrirlash",
  //
  licenseStatus: "Advokatlik maqomi",
  licenseStatusAdd: "Advokatlik maqomi qo'shish",
  licenseStatusUpdate: "Advokatlik maqomini tahrirlash",

  /////\\\\\    PROFILE      \\\\\\\\/////////
  /////\\\\\\                 \\\\\\\////////

  lawyerOne: "Advokat",
  profileInfo: "Profil ma'lumotlari",
  profileInfoUpdate: "Profil ma'lumotlarini tahrirlash",
  activity: "Mehnat faoliyati",
  profileAwards: "Davlat Mukofotlari",
  deputy: "Deputatligi",
  lawyerLicence: "Advokatlik litsenziyasi",

  //
  contragentOne: "Advokatlik birlashmasi",
  users: "Foydalanuvchilar",
  fileName: "Fayl nomi",
  control: "Boshqarish",
  roles: "Rollar",
  role: "Roli",
  view: "Ko'rinish",
  viewdate: "ma'lumotlarini ko'rish",
  viewRoles: "Rollarni ko'rish",
  depDetails: "Tashkilot ma'lumotlari",
  confirmation: "Tasdiqlash",
  getByMail: "Pochta orqali",
  depName: "Tashkilot nomi",
  depNotSelected: "Tashkilot tanlanmagan",
  map: "Joylashgan joyi",
  reference: "Ma'lumot",
  other: "Boshqa",
  selectOne: "Birini tanlang",
  selectOneOrMore: "Birini yoki bir nechta tanlang",
  enterToRemove: "O'chirish uchun bosing",
  enterToSelect: "Tanlsh uchun bosing",
  cv: "Obyektivka",
  findInfo: "Ma'lumotlarni olib kelish",
  chooseImage: "Rasm tanlang",
  deleteImage: "Rasmni o'chirish",
  // Login info
  loginUp: "O`zbekiston Respublikasi Adliya vazirligi",
  loginDown: "Advokatlarni avtomat tanlash tizimi",
  Username: "Foydalanuvchi nomi",
  userNameRequired: "Foydalanuvchi nomi talab etiladi",
  passwordRequired: "Parol talab etiladi",
  signIn: "Tizimga kirish",
  signOut: "Tizimdan chiqish",
  userProfile: "Foydalanuvchi profili",
  dashboardHeader: "Hududlararo Yuridik shaxslar bo'yicha statistikasi",
  dashboardHeader1: "Yuridik shaxslar bo'yicha statistika",
  allCount: "Umumiy soni",
  lawyerF: "Advokatlik firmasi",
  lawyerB: "Advokatlik byurosi",
  lawyerH: "Advokatlar hayʼati",
  totalInfo: "Barcha ma'lumotlar",
  Qolganlari: "Qolganlari",
  rquestAccepted: "Ariza qabul qilindi",
  requestAcceptedConnecting:
    "So'rov qabul qilindi, so'rov yuboruvchi bilan bog'laning",
  viewRequest: "Arizani ko'rish",
  sendRepot: "Hisobotni yuborish",
  successReport: "Hisobot muvaffaqiyatli yuborildi",
  reportFile: "Hisobot fayli",
  /* addition */
  submitAplication: "Ariza yuborish",
  curentStateApplication: "Arizangizning joriy holati",
  archiveApplication: "Arizalar arxivi",
  sendedDate: "Yuborilgan sana",
  send: "Yuborish",
  passportOrOneIdData:
    "O'zbekiston Respublikasi fuqarosi pasportining nusxasi yoki identifikatsiya ID-kartasi ma'lumotlari",
  educationData:
    "Oliy ma'lumot to'grisidagi diplom yoki vakolatli davlar organining xorijiy ta'lim muassasasida olingan oliy ma'lumot to'g'risidagi hujjatning nostrifikatsiyalash(ekvivalentligini qayd etish) to'g'risidagi guvohnoma nusxasi",
  workBookData:
    "Mehnat daftarchasining tasdiqlangan nusxasi yoki yuridik mutaxassislik bo'yicha ij stajini tasqdiqlovchi 'Elektron mehnat daftarchasi' tizimidan ko'chirma",
  internshipData:
    "Talabgor stajirovka o'tagan advokatlik tuzilmasi tomonidan berilgan tavsifnoma",
  $3x4Data:
    "Talabgorning 3 x 4 sm hajmdagi (so'nggi 6 oy ichida olingan) 2 dona rangli fotosurati",
  abilityDealingData:
    "Talabgorning muomalaga layoqatliligi to'g'risida ma'lumot",
  isConvictedData:
    "Talabgorning sudlanganligi yoki sudlanmaganligi to'g'risida ma'lumot",

  //REGIONS
  Uzbekistan: "O'zbekiston Respublikasi",
  Surxandaryo: "Surxandaryo viloyati",
  Toshkentshahar: "Toshkent shahar",
  Jizzah: "Jizzah viloyati",
  Qashqadaryo: "Qashqadaryo viloyati",
  Sirdaryo: "Sirdaryo viloyati",
  Toshkentviloyati: "Toshkent viloyati",
  Namangan: "Namangan viloyati",
  Fargona: "Farg'ona viloyati",
  QoraqalpogistonRespublikasi: "Qoraqalpog'iston Respublikasi",
  Navoi: "Navoi viloyati",
  Buxoro: "Buxoro viloyati",
  Xorazm: "Xorazm viloyati",
  Samarqand: "Samarqand viloyati",
  Andijon: "Andijon viloyati",
  archive: "Arxiv",
  totalContragent: "Jami Advokatlik birlashmalari",
  cancelStaff: "Ishdan bo'shatish",
  /* Addition */

  /* home banner */
  homeBannerText: "Bizning saytimiz sizga taqdim etadi",
  serviceLawyer: "“Yuridik yordam” axborot tizimi",
  register: "RO'YXATDAN O'TISH",
  sign_in: "KIRISH",
  /* addition */
  aboutUs: "Biz haqimizda",
  recentNews: "So'nggi yangiliklar",
  moreNews: "Ko'proq yangiliklar",
  readMore: "Ko'proq o'qish",
  moreLawyers: "Ko'proq advokatlar",
  detailed: "Batafsil",
  categories: "Kategoriyalar",
  statistics: "Statistika",
  branches: "Filiallar",
  branchesLists: "Barcha tuzilmalar",
  usefulLinks: "Foydali havolalar",
  presidentSite: "O'zbekiston Respublikasi Prezidenti rasmiy veb-sayti",
  uniqueCountryService: "Yagona interaktiv davlat xizmatlari portali",
  nationalTasks: "Barqaror rivojlanish sohasidagi milliy maqsad va vazifalar",
  oliyMajlis: "O‘zbekiston Respublikasi Oliy Majlisining Qonunchilik palatasi",
  openDataPortal: "O‘zbekiston Respublikasi ochiq ma’lumotlari portali",
  sendDoc:
    "Advokatlikka talabgor bo'lsangiz hujjatlarni shu shakl orqali yuborishingiz munkin",
  telegramBotClick: `e-advokat saytida yuzaga kelgan texnik muammolar to‘g‘risida ushbu bot orqali murojaat qililng`,
  /* footer */

  adres: "O‘zbekiston Respublikasi 100047, Toshkent sh., Sayilgoh ko‘chasi, 5",
  aboutMinistry: "Vazirlik haqida",
  ourCareer: "Faoliyatimiz",
  openJustice: "Ochiq adliya",
  vacancy: "Bo'sh ish o'rinlari",
  serviceInformation: "Axborot xizmati",
  e_library: "Elektron kutubxona",
  interactive: "Interaktiv xizmatlar",
  contact: "Aloqa",
  selectDocTemplate: "Shablon tanlang",
  docTemplateName: "Shablon nomi",
  notFoundDocTemplate: "Shablon topilmadi",
  docName: "Hujjat nomi",
  docNumber: "Hujjat raqami",
  docDate: "Hujjat sanasi",
  docTypeCategory: "Hujjat turi",
  notFoundDocTypeCategory: "Hujjat turi topilmadi",
  selectDocTypeCategory: "Hujjat turini tanlang",
  corruption: "Korrupsiya",
  genderEquality: "Gender tenglik",
  appeal: "Murojaat qilish",
  structureInformation: "Tuzilma ma'lumotlari",
  legAid: {
    checkingAccount: "Bank hisob raqami",
    mfo: "Bank MFO",
    maxRegion: "2 tadan ortiq hudud tanlay olmaysiz",
  },
  bankName: "Bank nomi",
  applications: "So'rovlar",
  createApplications: "So'rov yaratish",
  authorOfThePetition: "Murojaat muallifi",
  himself: "O'zi",
  representative: "Vakil",
  informationOfThePersonToWhomLegalAssistanceIsProvided:
    "Yuridik yordam ko'rsatilayotgan shaxsning ma'lumotlari",
  representativeInformation: "Vakil ma'lumotlari",
  fio: "F.I.O",
  investigatorStatus: {
    SEND: "Yuborildi",
    WAIT: "Javob kutilmoqda",
    CANCELLED: "Rad qilindi",
    ACCEPTED: "Qabul qilindi",
    CLOSED: "Advokat tomonidan tugatildi",
    COMPLETED: "Tugatildi",
    NOT_COMPLETED: "Tugatilmadi",
    PAYMENT: "To'lovga tasdiqlandi",
    EXPIRED: "Muddati o'tgan",
  },
  employmentInformation: "Ishga oid ma'lumot",
  representativeFile:
    "Ishonchnoma va (yoki) uning vakolatlarini tasdiqlovchi boshqa hujjatlar",
  psixFile:
    "Psixiatriya yordami ko'rsatilayotganligini tasdiqlovchi ma'lumotnoma",
  girlFile: "Ish sudda ko'rib chiqilayotganligi to'g'risidagi ma'lumotnoma",
  areaToWhichTheApplicationIsSent: "Murojaat yuborilayotgan hudud",
  districtToWhichTheApplicationIsSent: "Murojaat yuborilayotgan tuman",
  typeOfLegalAid: "Yuridik yordam turi",
  briefInformation: "Qisqacha ma'lumot",
  representativePinfl: "Vakil JSHSHR",
  representativeFio: "Vakil F.I.O",
  citizensInTheSocialSecurityRegister: "Ijtimoiy ximoya reystrida fuqarolar",
  personFio: "Yuridik yordam ko'rsatilayotgan shaxsni F.I.O",
  searchingLawyerRegion: "Advokat qidirish hududi",
  appealType: "Murojaat turi",
  passportSeriesAndNumber: "Pasport seriya va raqami",
  pinfl: "JShShR",
  forPersonsWithDisabilities: "Nogironligi bo’lgan shaxslar uchun",
  caseNumber: "Ish raqami",
  instance: "Instansiya",
  docTemplate: {
    list: "Shablonlar ro'yxati",
    create: "Shablon yaratish",
    all: "Tuzilma shablonlari",
    personal: "Yuklangan shablonlar",
  },
  letterDocuments: {
    list: "Xatlar va hujjatlar ro'yxati",
    create: "Xat yaratish",
  },
  courtName: "Sud nomi",
  theTimeWhenTheCourtIsAdjourned: "Sud otkaziladigan vaqt",
  internalNumberOfTheWorkDocument: "Ish hujjatiga oid ichki raqami",
  written: "Yozma",
  noMoreThan2500Words: "So'zlar 2500 tadan oshmasligi kerak!",
};
