import ApiService from "./api.service";
const main = "getInfo";
export default {
  getList({ type, params, legalAidId, orderBaseId, year, search, status }) {
    return ApiService.post(
      `${main}/ownOrder?type=${type || ""}&legalAidId=${
        legalAidId || ""
      }&orderBaseId=${orderBaseId || ""}&year=${year || ""}&search=${
        search || ""
      }&status=${status || ""}`,
      params
    );
  },
  LegalAidList() {
    return ApiService.post(`${main}/directoryLegalAidList`, {
      page: 0,
      limit: 100,
    });
  },
  listOwnLegalAidType() {
    return ApiService.get(`${main}/listOwnLegalAidType`);
  },
  orderBaseList() {
    return ApiService.post(`${main}/orderBaseList?search=`, {
      page: 0,
      limit: 100,
    });
  },
  create({
    contractDate,
    legalAidId,
    orderRegNumber,
    orderBaseId,
    personFullName,
    approvedDate,
    params,
    file,
    id,
  }) {
    let form = new FormData();
    form.append("file", file);
    params = JSON.stringify(params);
    form.append("key", params);
    form.append("personFullName", personFullName);
    return ApiService.post(
      `${main}/orderCreate?contractDate=${contractDate || ""}&legalAidId=${
        legalAidId || ""
      }&orderRegNumber=${orderRegNumber}&orderBaseId=${
        orderBaseId || ""
      }&approvedDate=${approvedDate || ""}`,
      form
    );
  },
  viewPdf(body) {
    return ApiService.post(`convert/view-pdf`, body);
  },
  mergePdfFiles(body) {
    return ApiService.post(`pdfFiles/mergePdfFiles`, body);
  },
  cancelOrder(orderId) {
    return ApiService.post(`${main}/cancelOrder?orderId=${orderId || ""}`);
  },
  orderCount() {
    return ApiService.get(`${main}/orderCount`);
  },
  countEmployee() {
    return ApiService.get(`${main}/countEmployee`);
  },
  getListForRequest() {
    return ApiService.get(`${main}/listForRequest`);
  },
  createDraft({
    id,
    contractDate,
    legalAidId,
    orderRegNumber,
    personFullName,
    orderBaseId,
  }) {
    if (id) {
      return ApiService.post(`${main}/updateDraft`, {
        id,
        orderRegNumber,
        orderBaseId,
        contractDate,
        legalAidId,
        personFullName,
      });
    } else {
      return ApiService.post(
        `${main}/createDraft?contractDate=${contractDate || ""}&legalAidId=${
          legalAidId || ""
        }&orderRegNumber=${orderRegNumber}&personFullName=${
          personFullName?.replaceAll("`", "'") || ""
        }&orderBaseId=${orderBaseId || ""}`
      );
    }
  },
  deleteDraft(orderId) {
    return ApiService.post(`${main}/deleteOrder?orderId=${orderId || ""}`);
  },
  getByOrderId(orderId) {
    return ApiService.post(`${main}/getByOrderId?orderId=${orderId || ""}`);
  },
  // Contragent orderlarini ko'rish
  getContragentOrder({
    type,
    params,
    legalAidId,
    orderBaseId,
    employeePnfl,
    year,
    search,
  }) {
    return ApiService.post(
      `${main}/employeeOrder?type=${type || ""}&legalAidId=${
        legalAidId || ""
      }&orderBaseId=${orderBaseId || ""}&search=${search || ""}&year=${
        year || ""
      }&employeePnfl=${employeePnfl || ""}`,
      params
    );
  },
  signatureDraft({ params, orderId }) {
    return ApiService.post(
      `${main}/signatureDraft?orderId=${orderId || ""}`,
      params
    );
  },
  orderAccess({ id, orderCheck, orderReason }) {
    return ApiService.post(
      `${main}/orderAccess?lawyerId=${id || ""}&orderCheck=${orderCheck}`,
      { orderReason }
    );
  },
  orderCreateCheck() {
    return ApiService.get(`${main}/orderCreateCheck`);
  },
};
