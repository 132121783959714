const AllData = "POSTS_DATA";
const changeState = "CHANGE_STATE";
const loading = "LOADING";
import PostService from "@/services/post.service";

const state = () => {
  return {
    list: [],
    total: 0,
    limit: 20,
    page: 0,
    search: "",
    loading: false,
  };
};

// actions
const actions = {
  async getList({ commit, state }) {
    let data = {
      search: state.search,
      params: {
        page: state.page,
        limit: state.limit,
      },
    };
    commit(loading, true);
    try {
      let res = await PostService.getList(data);
      if (res.data) {
        commit(AllData, res.data);
      }
    } finally {
      commit(loading, false);
    }
  },
};

// mutations
const mutations = {
  [AllData](state, data) {
    state.list = data.list || [];
    state.total = data.total || [];
  },
  [changeState](state, { search, page, limit }) {
    state.search = search == undefined ? state.search : search;
    state.page = page == undefined ? state.page : page;
    state.limit = limit == undefined ? state.limit : limit;
  },
  [loading](state, payload) {
    state.loading = payload;
  },
};

// getters
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
