export const locale = {
  actions: "Амаллар",
  name: {
    lt: "Nomi (lotin)",
    ru: "Название (русский)",
    uz: "Номи (кирилл)",
    en: "Name (english)",
  },
  lang: {
    russian: "Русский",
    uzbekLatin: "O‘zbek (Lotin)",
    uzbekKrill: "Ўзбек (кирилл)",
    english: "English",
  },
  corruption: "Коррупция",
  lawyerH: "Aдвокатлар ҳайъати",
  oneIIdAuth: "OneID autentifikatsiyasi",
  dutyScheduleHeader: "{region}да {year} йил {month} ойида жиноят ишлари бўйича давлат ҳисобидан юридик ёрдам кўрсатувчи адвокатларнинг НАВБАТЧИЛИК ЖАДВАЛИ",
  oneIIdAuthDesc:
    "OneID autentifikatsiyasi orqali tizimga kirish jarayoni boshlandi. Iltimos, Sahifa yuklanishi tugallanishini kuting...",
    bankName: "Банк номи",
  };
